import { Facebook, Instagram, Mail, WhatsApp } from "@mui/icons-material";
import { Box, Container, Grid, Link, Typography } from "@mui/material";

export default function Footer() {
     return (
          <Box
               sx={{
                    backgroundImage: 'url("/footer/map_grad.svg")',
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundColor: (theme) =>
                         theme.palette.mode === "light"
                              ? theme.palette.grey[100]
                              : theme.palette.grey[900],
               }}
          >
               <Box
                    component="footer"
                    sx={{
                         p: 6,
                         mt: 2,
                    }}
               >
                    <Container maxWidth="lg">
                         <Grid container spacing={5}>
                              <Grid item xs={12} sm={4}>
                                   <Typography
                                        variant="h6"
                                        color="text.primary"
                                        gutterBottom
                                   >
                                        About Us
                                   </Typography>
                                   <Typography
                                        variant="body2"
                                        color="text.secondary"
                                   >
                                        Your Bridge to Education & Work Visas.
                                   </Typography>
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                   <Typography
                                        variant="h6"
                                        color="text.primary"
                                        gutterBottom
                                   >
                                        Contact Us
                                   </Typography>
                                   <Typography
                                        variant="body2"
                                        color="text.secondary"
                                   >
                                        1st Floor, Office F5, Shaikh Complex,
                                        Juna Valsad Road, 396521, Chikhli,
                                        Gujarat, India
                                   </Typography>
                                   <Typography
                                        variant="body2"
                                        color="text.secondary"
                                   >
                                        Phone: +918238531623 | +918238531624
                                   </Typography>
                                   <Typography
                                        variant="body2"
                                        color="text.secondary"
                                   >
                                        support@foreignroutes.in
                                   </Typography>
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                   <Typography
                                        variant="h6"
                                        color="text.primary"
                                        gutterBottom
                                   >
                                        Follow Us
                                   </Typography>
                                   <Link
                                        href="https://wa.me/918238531623"
                                        target="_blank"
                                        color="inherit"
                                   >
                                        <WhatsApp />
                                   </Link>
                                   <Link
                                        href="https://www.facebook.com/"
                                        target="_blank"
                                        color="inherit"
                                        sx={{ pl: 1, pr: 1 }}
                                   >
                                        <Facebook />
                                   </Link>
                                   <Link
                                        href="https://www.instagram.com/foreignroutes/"
                                        color="inherit"
                                        sx={{ pl: 0, pr: 1 }}
                                        target="_blank"
                                   >
                                        <Instagram />
                                   </Link>
                              </Grid>
                         </Grid>
                         <Box mt={5}>
                              <Typography
                                   variant="body2"
                                   color="text.secondary"
                                   align="center"
                              >
                                   {"Copyright © "}
                                   <Link
                                        color="inherit"
                                        href="https://foreignroutes.in/"
                                   >
                                        www.foreignroutes.in
                                   </Link>{" "}
                                   {new Date().getFullYear()}
                                   {"."}
                              </Typography>
                         </Box>
                    </Container>
               </Box>
               <div
                    style={{
                         backgroundImage: 'url("/footer/map_grad.svg")',
                    }}
               ></div>
          </Box>
     );
}
