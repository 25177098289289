import React, { Component } from 'react'

// style 
const container = {
    margin: "50px",
    display: "flex",
    justifyContent:"center",
    flexWrap:"wrap"
}


export default class twoside extends Component {
  render() {
    return (
      <div className="container" style={container}>
        <div
          className="text"
          style={{
            height: "560px",
            width: "510px",
            margin: "10px",
          }}
        >
          <h2 style={{ textAlign: "center" }}>What we are ? What we do ?</h2>
          <div className="pragraphs">
            <p>
              We’ve been counselling students for educational Opportunities in
              Foreign countries and have been helping them realise their dream
              of studying abroad.
            </p>
            <p>
              Integer ullamcorper turpis sed orci porttitor, efficitur fringilla
              erat molestie. Curabitur molestie porta nisi, et condimentum sem
              tempus et.
            </p>
            <hr></hr>
            <ul>
              <li>Quisque sed ex nec eros ultricies porta.</li>
              <li>Phasellus sagittis turpis ac nisi lacinia sagittis.</li>
              <li>Fusce quis justo ac enim convallis semper.</li>
              <li>Sed ac nisi facilisis, egestas mi id, tristique libero.</li>
              <li>Integer semper mauris non sollicitudin faucibus.</li>
            </ul>
          </div>
        </div>

        <div
          className="picture"
          style={{
            // border: "2px solid red",
            height: "510px",
            width: "560px",
            margin: "10px",
          }}
        >
          <img
            src="https://shreeeducon.com/wp-content/uploads/2022/03/about-img-3.jpg"
            alt=""
            style={{ width: "80%", height: "90%" }}
          />
        </div>
      </div>
    );
  }
}
