import { Button, Card, Typography } from "@mui/material";
import { Carousel } from "react-responsive-carousel";

export default function Courses() {
	return (
		<Card sx={{ borderRadius: "16px", boxShadow: 4 }}>
			<Carousel
				infiniteLoop={true}
				autoPlay={true}
				showThumbs={false}
				showStatus={false}
			>
				<div>
					<img
						src="/countries/america.jpg"
						className="img_fill"
						style={{ height: "500px" }}
					/>
					<Button className="legend" variant="text" size="large">
						United States
					</Button>
				</div>
				<div>
					<img
						src="/countries/uk.jpg"
						className="img_fill"
						style={{ height: "500px" }}
					/>
					<Button variant="text" size="large" className="legend">
						United Kingdom
					</Button>
				</div>
				<div>
					<img
						src="/countries/new_zealand.jpg"
						className="img_fill"
						style={{ height: "500px" }}
					/>
					<Button variant="text" size="large" className="legend">
						New Zealand
					</Button>
				</div>
				<div>
					<img
						src="/countries/canada.jpg"
						className="img_fill"
						style={{ height: "500px" }}
					/>
					<Button variant="text" size="large" className="legend">
						Canada
					</Button>
				</div>
				<div>
					<img
						src="/countries/italy.jpg"
						className="img_fill"
						style={{ height: "500px" }}
					/>
					<Button variant="text" size="large" className="legend">
						Italy
					</Button>
				</div>
				<div>
					<img
						src="/countries/malta_church-5676085.jpg"
						className="img_fill"
						style={{ height: "500px" }}
					/>
					<Button variant="text" size="large" className="legend">
						Malta
					</Button>
				</div>
			</Carousel>
		</Card>
	);
}
