import {
  Box,
  Card,
  Chip,
  Divider,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import * as React from "react";
export default function Guide() {
  return (
    <Box
      sx={{
        width: "full",
      }}
    >
      <Stack
        spacing={{ xs: 1, sm: 2 }}
        direction="row"
        useFlexGap
        alignItems="center"
        flexWrap="wrap"
      >
        {makeCard(
          "STEP 1",
          "Course Selection",
          "Careful selection of course of study is the primary and the most significant phase in your plan to study abroad",
          "course.svg",
          "primary"
        )}
        {makeCard(
          "STEP 2",
          "University Selection",
          "Reputation, curriculum, faculties, course delivery, assessment methodology are considered while opting for an institution of learning",
          "university.svg",
          "secondary"
        )}
        {makeCard(
          "STEP 3",
          "Admission",
          "We assist students to identify all such general and specific demands of the universities for admission",
          "admission.svg",
          "primary"
        )}
        {makeCard(
          "STEP 4",
          "Financial Assistance",
          "We help accepted students to procure education loan from banking institution in India",
          "financial_assistance.svg",
          "secondary"
        )}
        {makeCard(
          "STEP 5",
          "Student Visa",
          "We thoroughly assists students and their financial sponsors on both general and specific visa requirements",
          "student_visa.svg",
          "primary"
        )}
        {makeCard(
          "STEP 6",
          "Pre Departure Counseling",
          "Studying in a new country can be exciting or thrilling. However, students should be well-informed about all aspects of living and studying",
          "departure.svg",
          "secondary"
        )}
      </Stack>
    </Box>
  );
}
function makeCard(chip, title, text, img, color) {
  return (
    <Card
      sx={{
        flexGrow: 1,
        p: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: 4,
        borderRadius: 3,
        height: "462px",
      }}
    >
      <Chip color={color} label={chip} sx={{ my: 1 }} />
      <Typography variant="h6" color="text.primary">
        {title}
      </Typography>
      <img
        src={"steps_section/" + img}
        alt="Foreign Routes Logo"
        style={{ maxWidth: "312px", width: "100%", maxHeight: "256px" }}
      />
      <p style={{ maxWidth: "300px", flexGrow: 1 }}>{text}</p>
    </Card>
  );
}
