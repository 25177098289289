import {
     Box,
     Container,
     CssBaseline,
     Drawer,
     Fab,
     Grid,
     IconButton,
     Link,
     Modal,
     ThemeProvider,
     createTheme,
} from "@mui/material";
import "./App.css";
import Home from "./components/nav/Home";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import * as React from "react";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { NavLink } from "react-router-dom";
import Button from "@mui/material/Button";
import Footer from "./components/footer/Footer";
import {
     Add,
     AddAlert,
     ChatBubble,
     Facebook,
     Instagram,
     Menu,
     WhatsApp,
} from "@mui/icons-material";
import NavMenu from "./components/nav/NavMenu.jsx";
import ContactDialog from "./components/body/ContactDialog";
import FormDialog from "./components/body/FormDialog";
import ContactUs from "./components/body/ContactUs";
import AboutUs from "./components/body/AboutUs";
import ECounselling from "./components/body/ECounselling";
import Coaching from "./components/body/Coaching";
import StudyAbroad from "./components/body/StudyAbroad";
import Blog from "./components/body/Blog";
import Services from "./components/Services.jsx";
const secondary = {
     main: "#1976d2",
     light: "#42a5f5",
     dark: "#0A6EBD",
     contrastText: "#fff",
};
const primary = {
     main: "#7cc13b",
     light: "#A9DC5B",
     dark: "#395144",
     contrastText: "#fff",
};
const lightTheme = createTheme({
     palette: {
          mode: "light",
          primary: primary,
          secondary: secondary,
     },
});
function HideOnScroll(props) {
     const { children, window } = props;
     const trigger = useScrollTrigger({
          target: window ? window() : undefined,
     });

     return (
          <Slide appear={false} direction="down" in={!trigger}>
               {children}
          </Slide>
     );
}

HideOnScroll.propTypes = {
     children: PropTypes.element.isRequired,
     window: PropTypes.func,
};
function App(props) {
     const [state, setState] = React.useState({
          left: false,
     });

     const toggleDrawer = (anchor, open) => (event) => {
          if (
               event.type === "keydown" &&
               (event.key === "Tab" || event.key === "Shift")
          ) {
               return;
          }

          setState({ ...state, [anchor]: open });
     };

     return (
          <Router>
               <ThemeProvider theme={lightTheme}>
                    <CssBaseline />
                    <main
                         style={{
                              minHeight: "100vh",
                              display: "flex",
                              flexDirection: "column",
                         }}
                    >
                         <React.Fragment>
                              <CssBaseline />
                              <React.Fragment key={"left"}>
                                   <Drawer
                                        anchor={"left"}
                                        open={state["left"]}
                                        onClose={toggleDrawer("left", false)}
                                   >
                                        <Box
                                             onClick={toggleDrawer(
                                                  "left",
                                                  false,
                                             )}
                                        >
                                             <NavMenu direction="column" />
                                        </Box>
                                   </Drawer>
                              </React.Fragment>
                              <HideOnScroll {...props}>
                                   <AppBar
                                        sx={{ bgcolor: "white", boxShadow: 2 }}
                                   >
                                        <Toolbar>
                                             <Container maxWidth="xl">
                                                  <Box
                                                       sx={{
                                                            marginTop: 1,
                                                            display: "flex",
                                                            justifyContent:
                                                                 "space-between",
                                                       }}
                                                  >
                                                       <Box
                                                            sx={{
                                                                 fontSize: 12,
                                                                 color: "#000000",
                                                            }}
                                                       >
                                                            Phone: +918238531623
                                                            | +918238531624,
                                                            Email:
                                                            support@foreignroutes.in
                                                       </Box>
                                                       <Box
                                                            sx={{
                                                                 fontWeight:
                                                                      "light",
                                                                 color: "#000000",
                                                            }}
                                                       >
                                                            <Link
                                                                 href="https://wa.me/918238531623"
                                                                 target="_blank"
                                                                 color="inherit"
                                                                 sx={{
                                                                      pl: 1,
                                                                      pr: 1,
                                                                 }}
                                                            >
                                                                 <WhatsApp
                                                                      sx={{
                                                                           color: "#4a4a4a",
                                                                      }}
                                                                 />
                                                            </Link>
                                                            <Link
                                                                 href="https://www.facebook.com/"
                                                                 target="_blank"
                                                                 color="#000000"
                                                            >
                                                                 <Facebook
                                                                      sx={{
                                                                           color: "#4a4a4a",
                                                                      }}
                                                                 />
                                                            </Link>
                                                            <Link
                                                                 href="https://www.instagram.com/foreignroutes/"
                                                                 color="inherit"
                                                                 sx={{
                                                                      pl: 1,
                                                                      pr: 1,
                                                                 }}
                                                                 target="_blank"
                                                            >
                                                                 <Instagram
                                                                      sx={{
                                                                           color: "#4a4a4a",
                                                                      }}
                                                                 />
                                                            </Link>
                                                       </Box>
                                                  </Box>
                                                  <Box
                                                       sx={{
                                                            display: "flex",
                                                            flexDirection:
                                                                 "row",
                                                            alignItems:
                                                                 "center",
                                                       }}
                                                  >
                                                       <div className="nav_drawer">
                                                            <IconButton
                                                                 color="inherit"
                                                                 aria-label="Open Drawer"
                                                                 edge="start"
                                                                 onClick={toggleDrawer(
                                                                      "left",
                                                                      true,
                                                                 )}
                                                                 sx={{ mr: 2 }}
                                                            >
                                                                 <Menu
                                                                      sx={{
                                                                           color: "black",
                                                                      }}
                                                                 />
                                                            </IconButton>
                                                       </div>
                                                       <Box
                                                            height="69px"
                                                            sx={{
                                                                 pt: 0,
                                                                 pb: 2,
                                                            }}
                                                       >
                                                            <NavLink
                                                                 style={{
                                                                      textDecoration:
                                                                           "none",
                                                                 }}
                                                                 to="/"
                                                            >
                                                                 <img
                                                                      src="fr_nav_logo.svg"
                                                                      className="nav_logo"
                                                                      alt="Foreign Routes Immigration Logo"
                                                                      style={{
                                                                           height: "100%",
                                                                      }}
                                                                 />
                                                            </NavLink>
                                                       </Box>
                                                       <Box
                                                            sx={{ flexGrow: 1 }}
                                                       />
                                                       <div className="nav_list">
                                                            <NavMenu direction="row" />
                                                       </div>
                                                       <FormDialog />
                                                  </Box>
                                             </Container>
                                        </Toolbar>
                                   </AppBar>
                              </HideOnScroll>
                              <Routes>
                                   <Route path="/" element={<Home />} />
                              </Routes>
                              <Routes>
                                   <Route
                                        path="/about-us"
                                        element={<AboutUs />}
                                   />
                              </Routes>
                              <Routes>
                                   <Route
                                        path="/contact-us"
                                        element={<ContactUs />}
                                   />
                              </Routes>
                              <Routes>
                                   <Route
                                        path="/e-counselling"
                                        element={<ECounselling />}
                                   />
                              </Routes>
                              <Routes>
                                   <Route
                                        path="/services"
                                        element={<Services />}
                                   />
                              </Routes>
                              <Routes>
                                   <Route
                                        path="/coaching"
                                        element={<Coaching />}
                                   />
                              </Routes>
                              <Routes>
                                   <Route
                                        path="/study-abroad"
                                        element={<StudyAbroad />}
                                   />
                              </Routes>
                              <Routes>
                                   <Route path="/blog" element={<Blog />} />
                              </Routes>
                         </React.Fragment>
                    </main>
               </ThemeProvider>
          </Router>
     );
}

export default App;
