import React, { useRef, useState, useEffect } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Test from "../3d/Test.jsx";
const CameraController = () => {
     const { camera, gl } = useThree();
     useEffect(() => {
          const controls = new OrbitControls(camera, gl.domElement);

          controls.minDistance = 3;
          controls.maxDistance = 20;
          return () => {
               controls.dispose();
          };
     }, [camera, gl]);
     return null;
};

export default function Logo() {
     return (
          <Canvas>
               <CameraController />
               <ambientLight intensity={0.8} />
               <directionalLight intensity={1.8} />
               <Test />
          </Canvas>
     );
}
