import React from "react";

const main = {
  margin: "50px",
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
};
const box = {
  textAlign: "center",
  //   height: "200px",
  width: "360px",
  padding: "10px",
};
const h3 = {
  color: "#6ec1e4",
};

const imgStyle = {
  filter: "hue-rotate(90deg)", // This will give a greenish hue to your images
};

export default function AboutBelow() {
  return (
    <div className="main" style={main}>
      <div className="box1" style={box}>
        <img src="\graduation2x.png" alt="" style={imgStyle} />
        <h3 style={h3}>IELTS For Study</h3>
        <p style={{ color: "#878787" }}>
          IELTS is recognised by more than 11,000 education and training
          providers worldwide.
        </p>
      </div>
      <div className="box2" style={box}>
        <img src="/migration2x.png" alt="" style={imgStyle} />
        <h3 style={h3}>IELTS For Migration</h3>
        <p style={{ color: "#878787" }}>
          Take IELTS to migrate to Australia, Canada, New Zealand and the UK.
        </p>
      </div>
      <div className="box3" style={box}>
        <img src="/work.png" alt="" style={imgStyle} />
        <h3 style={h3}>IELTS For Work</h3>
        <p style={{ color: "#878787" }}>
          Organisations around the world rely on IELTS to help them select the
          right people.
        </p>
      </div>
    </div>
  );
}
