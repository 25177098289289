import { Card } from "@mui/material";
import React from "react";
// styles
const main = {
     // border : "2px solid green",
     display: "flex",
     flexWrap: "wrap",
     justifyContent: "center",
     marginTop: "50px",
     aligniIems: "center",
};
const image = {
     // border : "1px solid red",
     //   maxHeight: "160px",
     maxWidth: "400px",
};
// style ends
const imageUrl = [
     "https://shreeeducon.com/wp-content/uploads/2022/03/admission1.jpg",
     "https://shreeeducon.com/wp-content/uploads/2022/03/a2.jpg",
     "https://shreeeducon.com/wp-content/uploads/2022/03/a3.jpg",
     "https://shreeeducon.com/wp-content/uploads/2022/03/visa.jpg",
     "https://shreeeducon.com/wp-content/uploads/2022/03/ff.jpg",
];
const title = [
     "Admission Guidance",
     "Education Counselling",
     "Selection of University",
     "Financial Estimations",
     "Visa Services",
];

export default function Images() {
     return (
          <main style={main}>
               {imageUrl.map((imageUrl, index) => (
                    <Card sx={{ m: 1 }}>
                         <div className="image" style={image}>
                              <img
                                   key={index}
                                   src={imageUrl}
                                   alt={`Image ${index + 1}`}
                                   style={{ width: "100%", height: "100%" }}
                              />
                              <h3
                                   style={{
                                        textAlign: "center",
                                        marginTop: "0px",
                                   }}
                              >
                                   {title[index]}
                              </h3>
                         </div>
                    </Card>
               ))}
          </main>
     );
}
