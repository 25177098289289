import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Card, Divider } from "@mui/material";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Card
          sx={{
            p: 3,
            boxShadow: 1,
            borderBottomLeftRadius: 14,
            borderBottomRightRadius: 14,
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
          }}
        >
          <Typography>{children}</Typography>
        </Card>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CoachingServices() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label={<Typography variant="h6">IELTS</Typography>}
            {...a11yProps(0)}
          />
          <Tab
            label={<Typography variant="h6">PTE</Typography>}
            {...a11yProps(1)}
          />
          <Tab
            label={<Typography variant="h6">TOEFL</Typography>}
            {...a11yProps(2)}
          />
          <Tab
            label={<Typography variant="h6">DUOLINGO</Typography>}
            {...a11yProps(3)}
          />
          <Tab
            label={<Typography variant="h6">SPOKEN ENGLISH</Typography>}
            {...a11yProps(4)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Typography variant="subtitle4">
          IELTS — The International English Language Testing System is one of
          the most recognized English language test globally. IELTS is use to
          check the English proficiency of individuals (from non-speaking
          English countries) who want to study or do a job in a English speaking
          country. The IELTS test assesses the candidates in four
          areas — listening, speaking, reading, and writing. The score is
          presented on a scale of 1–9 band, where 1 is the lowest and 9 the
          highest.
        </Typography>
        <Typography>
          The British Council developed IELTS in collaboration with the
          University of Cambridge and an Australian organization called IDP
          Education Australia, designed to act as proof to check your English
          speaking, writing, reading, and listening level. The IELTS test is
          acknowledged by over 10,000 institutions globally, including
          universities, colleges, governments, and employers. Depending on the
          countries and universities you are applying for, students require a
          specific IELTS score to get admission and a visa. In any of the
          country who are accepting IELTS has required score is 6.0 band no less
          than 5.5
        </Typography>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Typography>
          PTE — PTE is Pearson Test of English Academic. PTE is a multi-level
          test, remarkably like IELTS and TOEFL. It is a computer-based English
          language test to determine the reading, listening, writing, and
          speaking capability of the candidate. The non-native English speakers
          willing to study abroad must appear for this test.
        </Typography>
        <Typography>
          The three-hour-long computer-based test focusses on day-to-day English
          rather than high-level English language and tests a student on his/her
          ability to effectively understand the language as spoken daily. The
          multi-level grading system ensures a better understanding of the
          student’s proficiency in the English language.
        </Typography>
        <Typography>
          The question set often combines the two skills together speaking and
          writing. The exam format has 20 questions — speaking and writing 70–90
          mins, reading skills 30–40 mins, and listening 45–60 mins. The entire
          test lasted for 3 hours, and the speaking part is done via voice
          recording on the computer, and the test was sent for marking.
        </Typography>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Typography>
          TOEFL — The TOEFL (Test of English as a Foreign Language) is a
          patterned test that measures a student’s ability in the English
          language. The TOEFL test assesses the candidates in four
          areas — reading, listening, speaking, and writing. Major universities
          mainly use TOEFL scores as part of the admissions process.
        </Typography>
        <Typography>
          The TOEFL test attempts to test the student’s ability to communicate
          in English during their academic period. TOEFL is administered by the
          US-based organization the Education Testing Service, therefore, TOEFL
          is conducted only in American English.
        </Typography>
        <Typography>
          TOEFL is more likely majorly accepted by American academic institutes.
          The exam is conducted to judge the student’s ability to understand the
          English language. The exam tests the reading, writing, listening, and
          speaking skills of the students. TOEFL is a standardized exam for
          non-English language speakers to get admission in English speaking
          universities. Professional institutions conduct the test.
        </Typography>
        <Typography>
          TOEFL exam has four sections: Reading, Writing, Listening and
          Speaking. The scoring range of the exam is 0 to 120. The mean score of
          93 is considered good. The mean score is derived by adding the scores
          of each section.
        </Typography>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Typography>
          Duolingo English Test is the newest addition to the list of English
          proficiency tests that are accepted by universities and colleges
          around the world. Although, it isn’t as widely recognized as IELTS or
          TOEFL, it is gradually gaining popularity in study abroad space.
        </Typography>
        <Typography>
          An increasing number of students are choosing to appear for this test
          due to Duolingo’s in-depth course material, comparatively economical
          exam fees and an online mode of examination which can be taken from
          anywhere unlike other tests.
        </Typography>
        <Typography>
          Also, with each year, more and more international universities are
          accepting Duolingo English Test results as the certificate of English
          language proficiency. The scoring range of the exam is 0 to 160. The
          mean score of 120 no less than of 95 is considered good.
        </Typography>
        <Typography>
          The Duolingo English Test is accepted by almost 140 Canadian
          post-secondary institutions. It can be completed in just 1 hour and
          results are available in 2 days. The test is administered online
        </Typography>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <Typography>
          The training for enhanced communication skills is for working
          professionals. This program will help you become more competent and
          confident communicator. We ensure that you will improve your
          communication skills and build up more confidence to advance in your
          career.
        </Typography>
      </CustomTabPanel>
    </Box>
  );
}
