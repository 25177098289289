import { CalendarMonth, Call } from "@mui/icons-material";
import {
     Container,
     Box,
     Stack,
     Typography,
     Button,
     Divider,
} from "@mui/material";
import ECPopupButton from "../ecounselling/ECPopupButton";
import Footer from "../footer/Footer";
export default function ECounselling() {
     const countries = [
          "United States",
          "United kingdom",
          "New Zealand",
          "Canada",
          "Italy",
          "Malta",
     ];
     return (
          <div>
               <Container maxWidth="xl" sx={{ mt: 10, flexGrow: 1 }}>
                    <Box sx={{ boxShadow: 1, borderRadius: 4, px: 3, mb: 3 }}>
                         <div className="contact_head_bar">
                              <Box sx={{ flexGrow: 1 }}>
                                   <Typography variant="h2" gutterBottom>
                                        E-Counselling
                                   </Typography>
                                   <Typography variant="h5" gutterBottom>
                                        Schedule a call with us.
                                   </Typography>
                              </Box>
                              <Box sx={{ display: "flex" }}>
                                   <img
                                        src="contact-us/contact_us_person.svg"
                                        alt="contact us person"
                                        style={{ maxWidth: "312px" }}
                                   />
                              </Box>
                         </div>
                    </Box>
                    <Divider sx={{ m: 3 }} />
               </Container>
               <Container sx={{ mt: 10, flexGrow: 1 }}>
                    <Box
                         sx={{
                              width: "full",
                         }}
                    >
                         <Stack
                              spacing={{ xs: 1, sm: 5 }}
                              direction="row"
                              useFlexGap
                              alignItems="center"
                              justifyContent="center"
                              flexWrap="wrap"
                         >
                              {countries.map((country) => makeCard(country))}
                         </Stack>
                    </Box>
                    <Divider sx={{ m: 3 }} />
               </Container>
               <Footer />
          </div>
     );
}
function makeCard(country) {
     return (
          <Box
               sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "center",
                    boxShadow: 3,
                    flexGrow: 1,
                    borderRadius: 3,
                    p: 2,
               }}
          >
               <Box
                    sx={{
                         display: "flex",
                         alignContent: "center",
                         justifyContent: "center",
                    }}
               >
                    <CalendarMonth />
               </Box>
               <Typography variant="body" textAlign="center" gutterBottom>
                    Schedule a call for {country}
               </Typography>
               {/* <Button
				color="secondary"
				sx={{ borderRadius: 3 }}
				variant="contained"
				startIcon={<Call />}
			>
				Schedule Now
			</Button> */}

               <ECPopupButton country={country} />
          </Box>
     );
}
