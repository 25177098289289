import * as React from "react";
import Container from "@mui/material/Container";
import Body from "../body/Body";
import ContactDialog from "../body/ContactDialog";
import { Box } from "@mui/material";
import AnimBack from "../body/AnimBack";
import Footer from "../footer/Footer";

export default function DrawerAppBar(props) {
     return (
          <Box sx={{ pt: 10 }}>
               <ContactDialog />
               <Body />
               <Footer />
          </Box>
     );
}
