import { Divider, Typography } from "@mui/material";
import React from "react";

const container = {
     display: "flex",
     flexDirection: "row-reverse",
     flexWrap: "wrap",
     justifyContent: "space-between",
};

const pragraphs = {
     flex: "1 0 20em",
     margin: "1em",
};

const titleStyle = {
     fontSize: "2em",
     marginBottom: "0.5em",
};

const subtitleStyle = {
     fontSize: "1.5em",
     fontWeight: "bold",
     marginBottom: "0.5em",
};

const textStyle = {
     fontSize: "1em",
     lineHeight: "1.5",
     color: "#333",
     marginBottom: "1em",
};

const picture = {
     flex: "1 0 20em",
     margin: "1em",
};

const imageStyle = {
     width: "100%",
     height: "auto",
     borderRadius: "16px",
};

export default function HomeTwoSide() {
     return (
          <div style={container}>
               <div style={pragraphs}>
                    <Typography variant="h6">
                         Foreignroutes Immigration is a trustworthy, reputed and
                         well-founded Immigration consultancy. Our consultancy
                         offers assistance to students and working professionals
                         in exploring education and work opportunities overseas.
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 2 }}>
                         We’ve been counselling students for educational
                         Opportunities in Foreign countries and have been
                         helping them realise their dream of studying abroad.
                    </Typography>

                    <Divider sx={{ my: 2 }} />

                    <ul>
                         <li style={textStyle}>
                              Guiding through the Step-by-Step process and
                              eligibility.
                         </li>
                         <li style={textStyle}>
                              Give 100% Satisfaction To All Our Clients.
                         </li>
                         <li style={textStyle}>
                              Fusce quis justo ac enim convallis semper.
                         </li>
                         <li style={textStyle}>
                              Assistance In Financial Aid / Air Ticketing /
                              Forex / Airport Pick Up / Accommodation &
                              Pre-Departure Briefing.
                         </li>
                    </ul>
               </div>

               <div style={picture}>
                    <img style={imageStyle} src="/about-img-3.jpg" alt="" />
               </div>
          </div>
     );
}
