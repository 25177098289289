import { Box, Button, Card, Divider } from "@mui/material";
import * as React from "react";
import { NavLink } from "react-router-dom";
import StudyAbroadDialog from "../body/StudyAbroadDialog";
const style = {
     color: "#4a4a4a",
};
export default function NavMenu(props) {
     return (
          <Box sx={{ display: "flex", px: 5, flexDirection: props.direction }}>
               <img
                    alt="logo"
                    style={{ maxHeight: "256px" }}
                    src="logo.svg"
                    className="nav_logo nav_drawer"
               />
               <Divider sx={{ mb: 1 }} />
               <NavLink
                    style={{
                         textDecoration: "none",
                         display: "flex",
                         justifyContent: "center",
                    }}
                    to="/"
               >
                    <Button variant="text" style={style}>
                         Home
                    </Button>
               </NavLink>
               <Box>
                    {/* <NavLink
          style={{
            textDecoration: "none",
            display: "flex",
            justifyContent: "center",
          }}
          to="/e-counselling"
        >
          <Button variant="text" style={style}>
            Services
          </Button>
        </NavLink> */}
                    <NavLink
                         style={{
                              textDecoration: "none",
                              display: "flex",
                              justifyContent: "center",
                         }}
                         to="/services"
                    >
                         {makeServiceButton()}
                    </NavLink>
               </Box>
               <div
                    style={{
                         display: "flex",
                         flexDirection: "column",
                         flexWrap: "nowrap",
                         alignItems: "center",
                    }}
               >
                    <StudyAbroadDialog />
               </div>
               <NavLink
                    style={{
                         textDecoration: "none",
                         display: "flex",
                         justifyContent: "center",
                    }}
                    to="/blog"
               >
                    <Button variant="text" style={style}>
                         Blog
                    </Button>
               </NavLink>
               <NavLink
                    style={{
                         textDecoration: "none",
                         display: "flex",
                         justifyContent: "center",
                    }}
                    to="/contact-us"
               >
                    <Button variant="text" style={style}>
                         Contact&nbsp;Us
                    </Button>
               </NavLink>
               <NavLink
                    style={{
                         textDecoration: "none",
                         display: "flex",
                         justifyContent: "center",
                    }}
                    to="/about-us"
               >
                    <Button variant="text" style={style}>
                         About&nbsp;Us
                    </Button>
               </NavLink>
          </Box>
     );
}

const services = [
     "Student Visa",
     "Immigration Visa",
     "Visitor Visa",
     "Dependent Visa",
     "Coaching",
];
//button to show div on hover
function makeServiceButton() {
     return (
          <div
               id="serviceButton"
               onMouseEnter={() => {
                    document.getElementById("serviceList").style.display =
                         "block";
               }}
               onMouseLeave={() => {
                    document.getElementById("serviceList").style.display =
                         "none";
               }}
               style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    alignItems: "center",
               }}
          >
               <Button variant="text" style={style}>
                    Services
               </Button>
               <div id="serviceList" style={{ display: "none" }}>
                    <Card>
                         <Box
                              sx={{
                                   display: "flex",
                                   flexDirection: "column",
                                   flexWrap: "nowrap",
                                   alignItems: "flex-start",
                                   margin: 1,
                              }}
                              container
                              spacing={2}
                         >
                              {services.map((service) => (
                                   <>
                                        <Divider />
                                        <Button sx={{ flexGrow: 1 }}>
                                             <NavLink
                                                  to="/services"
                                                  style={{
                                                       textDecoration: "none",
                                                       color: "#1b5e20",
                                                  }}
                                                  underline="none"
                                             >
                                                  {service}
                                             </NavLink>
                                        </Button>
                                        <Divider />
                                   </>
                              ))}
                         </Box>
                    </Card>
               </div>
          </div>
     );
}
