import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Fab, Link, List, ListItem } from "@mui/material";
import { ChatBubble, Instagram, Mail, WhatsApp } from "@mui/icons-material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    borderRadius: "16px",
  },
}));

export default function ContactDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      style={{
        position: "fixed",
        zIndex: 1,
        height: "90vh",
        width: "100vw",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        pointerEvents: "none",
      }}
    >
      <div style={{ pointerEvents: "all" }}>
        <div>
          <Fab
            color="secondary"
            aria-label="add"
            sx={{
              m: 5,
            }}
            onClick={handleClickOpen}
          >
            <ChatBubble />
          </Fab>
          <BootstrapDialog
            fullWidth={true}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              Contact Us
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <List>
                <ListItem>
                  <Link
                    color="inherit"
                    href="https://wa.me/918238531623"
                    target="_blank"
                    style={{ width: "100%" }}
                  >
                    <Button
                      size="large"
                      variant="contained"
                      color="success"
                      style={{ width: "100%" }}
                      sx={{ py: 1.5, borderRadius: 3 }}
                      startIcon={<WhatsApp />}
                    >
                      WhatsApp
                    </Button>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link
                    style={{ width: "100%" }}
                    color="inherit"
                    href="https://www.instagram.com/foreignroutes/"
                    target="_blank"
                  >
                    <Button
                      size="large"
                      style={{ width: "100%" }}
                      variant="contained"
                      color="secondary"
                      sx={{ py: 1.5, borderRadius: 3 }}
                      startIcon={<Instagram />}
                    >
                      Instagram
                    </Button>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link
                    color="inherit"
                    href="mailto:support@foreignroutes.in"
                    target="_blank"
                    style={{ width: "100%" }}
                  >
                    <Button
                      size="large"
                      style={{
                        width: "100%",
                        backgroundColor: "black",
                      }}
                      variant="contained"
                      color="secondary"
                      sx={{ py: 1.5, borderRadius: 3 }}
                      startIcon={<Mail />}
                    >
                      Mail
                    </Button>
                  </Link>
                </ListItem>
              </List>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose}>
                Close
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </div>
      </div>
    </div>
  );
}
