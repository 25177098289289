import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  CardActions,
  IconButton,
  Typography,
  Box,
  Divider,
  Paper,
  Button,
} from "@mui/material";
import { red } from "@mui/material/colors";
import {
  ExpandMore,
  Favorite,
  FormatQuote,
  MoreVertOutlined,
  Share,
  Star,
} from "@mui/icons-material";
import Carousel from "react-material-ui-carousel";
export default function Review() {
  var items = [
    {
      name: "Harshraj Gohil",
      visa: "Canada Student Visa",
      rating: 5,
      description:
        "Excellent Knowledge with Extra Caring Efforts of Abhijit Sir. I have got my visa, and I am very thankful to him for giving me their best service. I got my visa within 10 days after submitting my file in VFS.",
      profile_url:
        "https://shreeeducon.com/wp-content/uploads/2022/03/testemonial1.jpg",
    },
    {
      name: "Prithviraj Parmar",
      visa: "Singapore Student Visa",
      rating: 5,
      description:
        "I am very happy to get my visa through Dredm Educon Overseas & Immigration. All the staff members are very supportive and have good knowledge with a kind nature. Because of them, I will be flying to my dream destination.",
      profile_url:
        "https://shreeeducon.com/wp-content/uploads/2022/03/testemonial2.jpg",
    },
    {
      name: "Nisarg Patel",
      visa: "Mauritius Student Visa",
      rating: 5,
      description:
        "I want to tell you all that I have given IELTS 3 to 4 times but did not get good bands. My future target is to study in Canada. For the same, I met Abhijit Sir in Dream Educon. He guided me for Mauritius Student Visa (Pathway Option for Canada), and I started my process with him. In 23 days, I got my visa with Abhijit Sir's proper and excellent help and guidance.",
      profile_url:
        "https://shreeeducon.com/wp-content/uploads/2022/03/testemonial3.jpg",
    },
    {
      name: "Shreya Rao",
      visa: "Singapore Student Visa",
      rating: 5,
      description:
        "I live in Baroda. I got a reference to Abhijit Sir (Foreignroutes Immigration) through my father’s friends. We met Abhijit Sir only once personally, and he guided me in a very nice way with excellent knowledge and many years of experience. I started my process for Singapore and finally got my visa. Thanks to Abhijit Sir.",
      profile_url:
        "https://shreeeducon.com/wp-content/uploads/2022/03/testemonial4.jpg",
    },
    {
      name: "Riddhi Panchal",
      visa: "Singapore Student Visa",
      rating: 5,
      description:
        "I got a reference to Abhijit Sir (Foreignroutes Immigration) through my friends. I got a lot of information about study abroad options from Abhijit Sir (Foreignroutes Immigration), and after that, I decided to apply in Singapore. Excellent service with good knowledge and supportive staff. I suggest to all go to Foreignroutes Immigration; you will get your success.",
      profile_url:
        "https://shreeeducon.com/wp-content/uploads/2022/03/testemonial5.jpg",
    },
    {
      name: "Bhautik Sosa",
      visa: "UK Student Visa",
      rating: 5,
      description:
        "Firstly, I would recommend it to anyone seeking guidance related to Visa services. Such a professional, warm, and welcoming response from the team there. They helped me with all my queries and doubts.",
      profile_url:
        "https://shreeeducon.com/wp-content/uploads/2022/04/WhatsApp-Image-2022-03-16-at-4.15.31-PM-3.jpeg",
    },
    {
      name: "Yaganik Vagadia",
      visa: "UK Student Visa",
      rating: 5,
      description:
        "It was a great experience. Thanks to Foreignroutes Immigration. I got my UK Student visa because of Foreignroutes Immigration; their experts are very helpful and understanding.",
      profile_url:
        "https://shreeeducon.com/wp-content/uploads/2022/04/WhatsApp-Image-2022-03-16-at-4.19.01-PM-150x150.jpeg",
    },
    {
      name: "Vikash Khimani",
      visa: "Switzerland Student Visa",
      rating: 5,
      description:
        "Thanks, Foreignroutes Immigration. My Switzerland Study Visa got successful. Staff are experienced and knowledgeable. They made my process very smooth and transparent.",
      profile_url:
        "https://shreeeducon.com/wp-content/uploads/2022/04/WhatsApp-Image-2022-03-16-at-4.05.44-PM-150x150.jpeg",
    },
    {
      name: "Himali Valand",
      visa: "UK Student Visa",
      rating: 5,
      description:
        "To the best of my knowledge, I recommend Foreignroutes Immigration for the UK Visa. My visa got possible due to them. I can just say thank you very much for your guide till the end of the process.",
      profile_url:
        "https://shreeeducon.com/wp-content/uploads/2022/04/Capture-150x150.jpg",
    },
  ];
  return (
    <Box sx={{ borderRadius: "16px", borderColor: "grey.500", boxShadow: 3 }}>
      <Carousel
        indicatorIconButtonProps={{
          style: {
            display: "none",
          },
        }}
      >
        {items.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Carousel>
    </Box>
  );
}
function Item(props) {
  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <Box
        sx={{
          mb: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Avatar alt="Name" src={props.item.profile_url} />
        <Box sx={{ ml: 1, display: "flex", flexDirection: "column" }}>
          <Typography align="left" variant="h5" color="text.primary">
            {props.item.name}
          </Typography>
        </Box>
      </Box>
      <Typography align="left" variant="h6" color="text.secondary">
        {props.item.visa}
      </Typography>
      {ratingStars(props.item.rating)}
      <p>
        <FormatQuote style={{ transform: "scaleX(-1)" }} />
        <strong> {props.item.description}</strong>
        <FormatQuote />
      </p>
    </Box>
  );
}
function ratingStars(rating) {
  if (rating > 5) rating = 5;
  if (rating < 0) rating = 0;
  var stars = [];
  for (let i = 0; i < rating; i++) {
    stars.push(<Star style={{ color: "#FFD700" }} />);
  }
  return stars;
}
