import { Instagram, Mail, MailOutline, WhatsApp } from "@mui/icons-material";
import {
     Box,
     Card,
     Container,
     Divider,
     IconButton,
     Typography,
} from "@mui/material";
import Footer from "../footer/Footer";

export default function AboutUs() {
     return (
          <>
               <Container maxWidth="xl" sx={{ mt: 10 }}>
                    <Box sx={{ boxShadow: 2, borderRadius: 4, px: 3, mb: 3 }}>
                         <div className="contact_head_bar">
                              <Box sx={{ flexGrow: 1 }}>
                                   <Typography variant="h2" gutterBottom>
                                        About Us
                                   </Typography>

                                   <Typography variant="h5" gutterBottom>
                                        Meet our Foreign Routes team members
                                   </Typography>
                              </Box>
                              <Box sx={{ display: "flex" }}>
                                   <img
                                        src="about-us/team.svg"
                                        alt="contact us person"
                                        style={{ maxWidth: "312px" }}
                                   />
                              </Box>
                         </div>
                    </Box>
                    <Divider sx={{ m: 3 }} />
                    <Box>
                         <Card sx={{ mt: 1, boxShadow: 4, borderRadius: 3 }}>
                              <div className="dynamic_box" style={{}}>
                                   <img
                                        style={{
                                             flexGrow: 1,
                                             width: "100%",
                                             height: "450px",
                                             objectFit: "cover",
                                        }}
                                        src="https://picsum.photos/1024/1024"
                                   ></img>
                                   <Box sx={{ flexGrow: 1, ml: 5 }}>
                                        <Divider sx={{ m: 3, opacity: 0 }} />
                                        <Typography variant="h4" gutterBottom>
                                             h4. Heading
                                        </Typography>
                                        <Divider sx={{ m: 3 }} />
                                        <Typography
                                             variant="body1"
                                             gutterBottom
                                        >
                                             body1. Lorem ipsum dolor sit amet,
                                             consectetur adipisicing elit. Quos
                                             blanditiis tenetur unde suscipit,
                                             quam beatae rerum inventore
                                             consectetur, neque doloribus,
                                             cupiditate numquam dignissimos
                                             laborum fugiat deleniti? Eum quasi
                                             quidem quibusdam.
                                        </Typography>
                                        <Divider sx={{ m: 3 }} />
                                        <Typography variant="h6" gutterBottom>
                                             h6. Heading
                                        </Typography>
                                        <Divider sx={{ m: 3, opacity: 0 }} />
                                        <Box>
                                             <IconButton
                                                  sx={{ color: "black" }}
                                             >
                                                  <WhatsApp />
                                             </IconButton>
                                             <IconButton
                                                  sx={{ color: "black" }}
                                             >
                                                  <Instagram />
                                             </IconButton>
                                             <IconButton
                                                  sx={{ color: "black" }}
                                             >
                                                  <MailOutline />
                                             </IconButton>
                                        </Box>
                                        <Divider sx={{ m: 2, opacity: 0 }} />
                                   </Box>
                              </div>
                         </Card>
                         <Divider sx={{ m: 3 }} />
                    </Box>
                    <Divider sx={{ m: 3 }} />
               </Container>
               <Footer />
          </>
     );
}
