import {
     Avatar,
     Box,
     Card,
     CardContent,
     CardHeader,
     Chip,
     Container,
     Divider,
     Grid,
     Stack,
     Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import Courses from "./Courses";
import Review from "./Review";
import Header from "./Header";
import Guide from "./Guide";
import CoachingServices from "./CoachingServices";
import Ourpartners from "../akash/Ourpartners";
import Aboutus from "../akash/Aboutus";
import AboutBelow from "../akash/AboutBelow";
import Countries from "../akash/Countries";
import HomeTwoSide from "../akash/HomeTwoSide";
import Experience from "../akash/Experience";
import SuccessStory from "../akash/SuccessStory";
import AnimBack from "./AnimBack";
export default function Body() {
     return (
          <Grid
               spacing={0}
               direction="column"
               alignItems="center"
               justifyContent="center"
               sx={{ minHeight: "100vh" }}
          >
               <Grid item xs={3}>
                    <Container maxWidth="xl">
                         <Header />
                    </Container>
                    <Container maxWidth="xl">
                         <Divider sx={{ m: 5 }} />
                         <Card sx={{ boxShadow: 3, borderRadius: "16px" }}>
                              <CardContent>
                                   <Box
                                        sx={{
                                             margin: 2,
                                        }}
                                   >
                                        <h3>
                                             We are providing a wide range of
                                             services including
                                        </h3>
                                        <p>
                                             Foreign Routes Immigration is a
                                             trustworthy, reputed and
                                             well-founded Immigration
                                             consultancy in Ahemdabad. We are
                                             extend our expert counsel in a wide
                                             range of immigration services such
                                             as student visa, visitor visa,
                                             dependent visa and immigration
                                             visa.
                                        </p>
                                   </Box>
                                   <Stack
                                        spacing={{ xs: 1, sm: 2 }}
                                        marginTop={2}
                                        direction="row"
                                        useFlexGap
                                        alignItems="center"
                                        flexWrap="wrap"
                                   >
                                        {makeVisaTypeCard(
                                             "Visitor Visa",
                                             "Visitor Visa is the permission granted by a sovereign nation to a  visitor of the country permitting entry for a specific purpose.",
                                             "https://shreeeducon.com/wp-content/uploads/2022/02/icon-visa-service.png",
                                             "#EEF5FF",
                                             "secondary",
                                        )}
                                        {makeVisaTypeCard(
                                             "Student Visa",
                                             "If you are thinking about the study abroad, our expert team of student visa. Highest Visa Success Ratio.",
                                             "https://shreeeducon.com/wp-content/uploads/2022/02/icon-ovearseas-education.png",
                                             "#F2FFE9",
                                             "primary",
                                        )}
                                        {makeVisaTypeCard(
                                             "Dependent Visa",
                                             "The Dependent Visa is a helpful visa category planned by countries. File prepared by our expert Team",
                                             "https://shreeeducon.com/wp-content/uploads/2022/02/icon-coaching.png",
                                             "#F2FFE9",
                                             "primary",
                                        )}
                                        {makeVisaTypeCard(
                                             "Immigration Visa",
                                             "In real Immigration Visa is a big term and further divides to many categories. Lowest processing charges.",
                                             "https://shreeeducon.com/wp-content/uploads/2022/02/icon-immigration.png",
                                             "#EEF5FF",
                                             "secondary",
                                        )}
                                   </Stack>
                              </CardContent>
                         </Card>
                         <Divider sx={{ m: 3 }} />
                         <Typography
                              textAlign="center"
                              variant="h4"
                              gutterBottom
                         >
                              Countries
                         </Typography>
                         <Courses />
                         <Divider sx={{ m: 3 }} />
                         <Typography
                              textAlign="center"
                              variant="h4"
                              gutterBottom
                         >
                              Guide
                         </Typography>
                         <Guide />
                         <Divider sx={{ m: 3 }} />
                         <Typography
                              textAlign="center"
                              variant="h4"
                              gutterBottom
                         >
                              Coaching Services
                         </Typography>
                         <CoachingServices />
                         <Divider sx={{ m: 3 }} />

                         {/* <Typography
                              textAlign="center"
                              variant="h4"
                              gutterBottom
                         >
                              Out Partner
                         </Typography>
                         <Ourpartners />
                         <Divider sx={{ m: 3 }} /> */}

                         <Typography
                              textAlign="center"
                              variant="h4"
                              gutterBottom
                         >
                              Foreignroutes Immigration Welcome to For Services!
                         </Typography>
                         <Aboutus />
                         <AboutBelow />
                         <Divider sx={{ mt: 10, mb: 4 }} />

                         <Typography
                              textAlign="center"
                              variant="h4"
                              gutterBottom
                         >
                              Choose Your Country
                         </Typography>
                         <div className="text" style={{ textAlign: "center" }}>
                              <p>
                                   With experts to assist you at every step of
                                   your dreams, we offer services to the
                                   following countries For More Details Click
                                   Here !
                              </p>
                         </div>
                         <Countries />
                         <Divider sx={{ m: 3 }} />

                         <Typography
                              textAlign="center"
                              variant="h5"
                              gutterBottom
                         >
                              What we are? What we do?
                         </Typography>
                         <HomeTwoSide />
                         <Divider sx={{ m: 3 }} />

                         <Typography
                              textAlign="center"
                              variant="h5"
                              gutterBottom
                         >
                              Experience
                         </Typography>
                         <Experience />
                         <Divider sx={{ m: 3 }} />

                         <Typography
                              textAlign="center"
                              variant="h4"
                              gutterBottom
                         >
                              Success Student Story…
                         </Typography>
                         <Review />
                         <Divider sx={{ m: 3 }} />
                    </Container>
               </Grid>
          </Grid>
     );

     function makeVisaTypeCard(visa, content, img, bgColor, chipColor) {
          return (
               <Card
                    sx={{
                         flexGrow: 1,
                         p: 2,
                         display: "flex",
                         flexDirection: "column",
                         justifyContent: "center",
                         alignItems: "center",
                         boxShadow: 1,
                         borderRadius: 3,
                         backgroundColor: bgColor,
                    }}
               >
                    <CardContent>
                         <Box
                              sx={{
                                   display: "flex",
                                   flexDirection: "column",
                                   justifyContent: "center",
                                   alignItems: "center",
                              }}
                         >
                              <h4 style={{ marginBottom: 20 }}>{visa}</h4>
                              <img
                                   src={img}
                                   alt="image"
                                   style={{ marginBottom: 20 }}
                              ></img>
                              <p style={{ maxWidth: "600px", flexGrow: 1 }}>
                                   {content}
                              </p>
                              <Chip
                                   color={chipColor}
                                   label="Learn More"
                                   sx={{ mt: 4 }}
                              />
                         </Box>
                    </CardContent>
               </Card>
          );
     }
}
