import { Box, Card } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";

const content = {
  display: "flex",
  justifyContent: "center",
};

const Container = styled("div")({
  padding: "10px",
  backgroundColor: "#EFEFEF",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  "@media (min-width:600px)": {
    flexDirection: "row",
  },
});

export default function Aboutus() {
  return (
    <Card>
      <Container>
        <Box sx={{ margin: 2 }}>
          <h2
            style={{
              fontSize: "25px",
              color: "black",
              fontFamily: "sans-serif",
              marginBottom: "10px",
            }}
          >
            About Us
          </h2>
          <p>Best Immigration Consultants in Ahemdabad</p>
          <p>
            We at Shree Educon Overseas & Immigration , extend our expert
            counsel in a wide range of immigration services such as student
            visa, visitor visa, dependent visa and immigration visa.
          </p>
          <p>
            We feel very grate and pleasure to introduce ourselves as Shree
            Educon Overseas & Immigration as one of the successful and client
            oriented leading company in Gujarat – India. We are very careful and
            dedicated about promoting Abroad Education for our students or
            candidate who are willing to study in their Dream Foreign Country
            for career prospects and opportunity. As owner of the Shree Educon
            Overseas & Immigration.
          </p>
        </Box>
        <Box>
          <img
            src="/contact-form.jpg"
            alt=""
            style={{ height: "100%", width: "100%", borderRadius: "5px" }}
          />
        </Box>
      </Container>
    </Card>
  );
}
