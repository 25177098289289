import React from "react";
import Images from "./akash/Images";
import Twoside from "./akash/Twoside";
import Countries from "./akash/Countries";
import {
     Box,
     Container,
     Typography,
     Button,
     Card,
     Divider,
} from "@mui/material";
import Footer from "./footer/Footer";

const Services = () => {
     return (
          <>
               <Container maxWidth="xl" sx={{ mt: 10 }}>
                    {makeFluidCardList()}
                    <Images />
                    <Twoside />
                    <Countries />
               </Container>
               <Footer />
          </>
     );
};

export default Services;

const cards = [
     {
          type: "Visitor Visa",
          description:
               "The permission granted by a sovereign nation to a visitor of the country permitting entry for a specific purpose.",
          link: "Read More",
          img: "https://shreeeducon.com/wp-content/uploads/2022/02/icon-visa-service.png",
     },
     {
          type: "Student Visa",
          description:
               "If you are thinking about the study abroad, our expert team of student visa. Highest Visa Success Ratio.",
          link: "Read More",
          img: "https://shreeeducon.com/wp-content/uploads/2022/02/icon-ovearseas-education.png",
     },
     {
          type: "Dependent Visa",
          description:
               "The Dependent Visa is a helpful visa category planned by countries. File prepared by our expert Team",
          link: "Read More",
          img: "https://shreeeducon.com/wp-content/uploads/2022/02/icon-coaching.png",
     },
     {
          type: "Immigration Visa",
          description:
               "In real Immigration Visa is a big term and further divides to many categories. Lowest processing charges.",
          link: "Read More",
          img: "https://shreeeducon.com/wp-content/uploads/2022/02/icon-immigration.png",
     },
];

function makeFluidCardList() {
     return (
          <Box
               sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    mt: 4,
               }}
          >
               {cards.map((card) => makeCard(card))}
          </Box>
     );
}
function makeCard(card) {
     return (
          <Card sx={{ m: 2 }}>
               <Box
                    sx={{
                         maxWidth: 330,
                         p: 4,
                         display: "flex",
                         flexDirection: "column",
                         alignItems: "center",
                    }}
               >
                    <img src={card.img} style={{ maxWidth: 100 }} />
                    <Typography
                         variant="h5"
                         align="center"
                         sx={{ mt: 2 }}
                         gutterBottom
                    >
                         {card.type}
                    </Typography>
                    <Divider sx={{ mb: 1 }} />
                    <Typography variant="body1" align="justify">
                         {card.description}
                    </Typography>
                    <Box
                         sx={{
                              display: "flex",
                              justifyContent: "center",
                              mt: 4,
                         }}
                    >
                         <Button variant="contained" color="primary">
                              Read More
                         </Button>
                    </Box>
               </Box>
          </Card>
     );
}
