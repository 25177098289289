import { Box, Typography } from "@mui/material";
import Logo from "../3d/Logo.jsx";
import AnimBack from "./AnimBack.jsx";
export default function Header() {
     return (
          <Box sx={{ boxShadow: 4, borderRadius: 4, mt: 2, pl: 3, pr: 1 }}>
               <Box>
                    <div className="contact_head_bar">
                         <Box sx={{ flexGrow: 1 }}>
                              <Typography variant="h3" gutterBottom>
                                   Foreignroutes Immigration
                              </Typography>
                              <Typography variant="h4" gutterBottom>
                                   Immigration Solutions
                              </Typography>
                              <Typography variant="h4" gutterBottom>
                                   Visa Experts
                              </Typography>
                              <Typography variant="subtitle2" gutterBottom>
                                   Foreign Routes, Your Bridge to Education &
                                   Work Visas
                              </Typography>
                         </Box>
                         <div className="box_3d">
                              {/* <img
						src="logo_base.svg"
						alt="Foreign Routes Logo"
						style={{ maxWidth: "312px", width: "100%" }}
					/> */}
                              {/* <img
						src="/3d/anim0001.gif"
						alt="Foreign Routes Logo"
						style={{ maxWidth: "512px", width: "100%" }}
					/> */}
                              <Logo />
                         </div>
                    </div>
               </Box>
          </Box>
     );
}
