import { Mail, WhatsApp } from "@mui/icons-material";
import {
     Box,
     Button,
     Card,
     Container,
     Divider,
     Link,
     Typography,
} from "@mui/material";
import Footer from "../footer/Footer";

export default function ContactUs() {
     return (
          <>
               {" "}
               <Container maxWidth="xl" sx={{ pt: 12 }}>
                    <Box sx={{ boxShadow: 1, borderRadius: 4, px: 3, mb: 3 }}>
                         <div className="contact_head_bar">
                              <Box sx={{ flexGrow: 1 }}>
                                   <Typography variant="h2" gutterBottom>
                                        Find Us Here
                                   </Typography>
                                   <Typography variant="h5" gutterBottom>
                                        You can contact us from these mediums.
                                   </Typography>
                              </Box>
                              <Box sx={{ display: "flex" }}>
                                   <img
                                        src="contact-us/contact_us_person.svg"
                                        alt="contact us person"
                                        style={{ maxWidth: "312px" }}
                                   />
                              </Box>
                         </div>
                    </Box>
                    <Divider sx={{ m: 3 }} />
                    <Typography variant="h4" gutterBottom>
                         Social Links
                    </Typography>
                    <div className="contact_us_links">
                         <Link
                              color="inherit"
                              href="https://wa.me/918238531623"
                              target="_blank"
                              sx={{ mx: 1, mt: 1, flexGrow: 1 }}
                         >
                              <Button
                                   color="success"
                                   sx={{ width: "100%", borderRadius: 3 }}
                                   variant="contained"
                                   startIcon={<WhatsApp />}
                              >
                                   WhatsApp
                              </Button>
                         </Link>
                         <Link
                              color="inherit"
                              href="https://www.instagram.com/foreignroutes/"
                              target="_blank"
                              sx={{
                                   mx: 1,
                                   mt: 1,
                                   flexGrow: 1,
                                   borderRadius: 3,
                              }}
                         >
                              <Button
                                   color="secondary"
                                   variant="contained"
                                   sx={{ width: "100%", borderRadius: 3 }}
                                   startIcon={<WhatsApp />}
                              >
                                   Instagram
                              </Button>
                         </Link>
                         <Link
                              color="inherit"
                              href="mailto:support@foreignroutes.in"
                              target="_blank"
                              sx={{
                                   mx: 1,
                                   mt: 1,
                                   flexGrow: 1,
                                   borderRadius: 3,
                              }}
                         >
                              <Button
                                   variant="contained"
                                   startIcon={<Mail />}
                                   sx={{
                                        width: "100%",
                                        borderRadius: 3,
                                        backgroundColor: "black",
                                   }}
                              >
                                   Email
                              </Button>
                         </Link>
                    </div>
                    <Divider sx={{ m: 3 }} />
                    <Typography variant="h4" gutterBottom>
                         Our Address
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                         1st Floor, Office F5, Shaikh Complex, Juna Valsad Road,
                         396521, Chikhli, Gujarat, India
                    </Typography>
                    <Card
                         sx={{
                              boxShadow: 1,
                              width: 1,
                              mt: 6,
                              mb: 2,
                              borderRadius: "16px",
                         }}
                    >
                         <iframe
                              src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d768.1719659155738!2d73.05876886843114!3d20.7572047786498!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1693404106399!5m2!1sen!2sin"
                              height="450"
                              style={{ width: "100%", border: "0" }}
                              loading="lazy"
                              referrerpolicy="no-referrer-when-downgrade"
                         ></iframe>
                    </Card>
                    <Divider sx={{ m: 3 }} />
               </Container>
               <Footer />
          </>
     );
}
//https://maps.google.com/maps/search/Chikhli%20396521%2C%20GJ%2C%20India/@20.7572,73.0587,17z?hl=en
