import React, { useRef, useState } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { useLoader, useFrame } from "@react-three/fiber";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { SphereGeometry } from "three";
export default function Model(props) {
     const group = useRef();
     const { nodes, materials, animations } = useGLTF("/3d/test.glb");
     const sphereColorMap = useLoader(TextureLoader, "/3d/color.png");
     const colorMap = useLoader(TextureLoader, "3d/banner.png");
     const [torusRotation, setTorusRotation] = useState(0);
     const bumpMap = useLoader(TextureLoader, "3d/bump.jpg");
     useFrame(() => {
          setTorusRotation((prevRotation) => prevRotation + 0.01);
     });
     return (
          <group scale={1.8} ref={group} {...props} dispose={null}>
               <group name="Scene">
                    <mesh
                         name="Sphere"
                         // geometry={nodes.Sphere.geometry}
                         geometry={new SphereGeometry(1, 40, 40)}
                         // material={materials.Material}
                         material={materials["Material"]}
                         scale={1.1}
                         // rotation={[-Math.PI, 0, -Math.PI]}
                         rotation={[-Math.PI, torusRotation + 0, 0.1]}
                    >
                         {/* <meshStandardMaterial map={sphereColorMap} /> */}
                    </mesh>
                    <mesh
                         name="Cube"
                         geometry={nodes.Cube.geometry}
                         material={nodes.Cube.material}
                         rotation={[0.027, 0.042, 0.607]}
                    >
                         <mesh
                              name="Torus"
                              geometry={nodes.Torus.geometry}
                              material={materials["Material.001"]}
                              // rotation={[0, 0.279, 0]}
                              rotation={[0, torusRotation, 0]}
                         >
                              <mesh
                                   name="Plane"
                                   geometry={nodes.Plane.geometry}
                                   material={materials["Material.003"]}
                                   rotation={[-0.148, 0.609, -0.693]}
                                   position={[-0.001, -0.04, 0.001]}
                                   scale={0.496}
                              >
                                   <meshStandardMaterial color={"#F0EDD4"} />
                                   <mesh
                                        name="Cylinder"
                                        geometry={nodes.Cylinder.geometry}
                                        material={materials["Material.002"]}
                                        rotation={[0, 0.044, 0]}
                                        scale={2.014}
                                   >
                                        <meshStandardMaterial map={colorMap} />
                                   </mesh>
                              </mesh>
                         </mesh>
                    </mesh>
               </group>
          </group>
     );
}

useGLTF.preload("/3d/test.glb");
