import { AccountBalance, Group, WorkspacePremium } from "@mui/icons-material";
import React from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { fa-solid, fa-building-columns } from '@fortawesome/free-solid-svg-icons'

const main = {
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
};
const box = {
  textAlign: "center",
  height: "200px",
  width: "360px",
  padding: "10px",
};
const h3 = {
  color: "#6ec1e4",
};
const iconStyle = {
  fontSize: 100,
  color: "#263a69",
};
export default function Experience() {
  return (
    <div className="main" style={main}>
      <div className="box1" style={box}>
        <AccountBalance style={iconStyle} />
        <h3 style={h3}>8+ Years Experience</h3>
        <p style={{ color: "#878787" }}>
          8 Years Of Experience In Recruiting Students For Study Abroad.
          Complete Assistance For Application To Visa.
        </p>
      </div>
      <div className="box2" style={box}>
        <WorkspacePremium style={iconStyle} />
        <h3 style={h3}>1500+ Immigrant Cases</h3>
        <p style={{ color: "#878787" }}>
          We Provide Customized & Truthful Solution To All The Students.
          Selection For Right County, University Or College & Programme.
        </p>
      </div>
      <div className="box3" style={box}>
        <Group style={iconStyle} />
        <h3 style={h3}>Immigration Experts</h3>
        <p style={{ color: "#878787" }}>
          Focus On Career Counselling With Trained & Experienced Staff.
          Guaranteed Admissions From Partner College & University.
        </p>
      </div>
    </div>
  );
}
