import { Call, HistoryEdu } from "@mui/icons-material";
import {
     Container,
     Typography,
     Button,
     Box,
     Divider,
     Stack,
} from "@mui/material";
import PopupButton from "../coaching/PopupButton";
import Footer from "../footer/Footer";
export default function Coaching() {
     const courses = [
          {
               courseName: "GRE",
               courseDetails:
                    "The Graduate Record Examinations is a standardized test that is an admissions requirement for many graduate schools in the United States and Canada and a few other countries.",
          },
          {
               courseName: "DUOLINGO",
               courseDetails:
                    "The Duolingo English Test is a standardized test of English language but designed to be internet first rather than paper based. ",
          },
          {
               courseName: "SELT",
               courseDetails:
                    "Secure English Language Test – a term used by UK Visas and Immigration (UKVI) to describe the English language tests they'll accept as evidence of your English Language level for your visa application.",
          },
          {
               courseName: "IELTS",
               courseDetails:
                    "The International English Language Testing System, is an international standardized test of English language proficiency for non-native English language speakers. It is jointly managed by the British Council, IDP: IELTS Australia and Cambridge Assessment English",
          },
     ];
     return (
          <>
               {" "}
               <Container sx={{ mt: 10, flexGrow: 1 }}>
                    <Box sx={{ boxShadow: 1, borderRadius: 4, px: 3, mb: 3 }}>
                         <div className="contact_head_bar">
                              <Box sx={{ flexGrow: 1 }}>
                                   <Typography variant="h2" gutterBottom>
                                        Coaching
                                   </Typography>
                                   <Typography variant="h5" gutterBottom>
                                        Apply for our coaching
                                   </Typography>
                              </Box>
                              <Box sx={{ display: "flex" }}>
                                   <img
                                        src="coaching/coaching.svg"
                                        alt="coaching"
                                        style={{ maxWidth: "312px" }}
                                   />
                              </Box>
                         </div>
                    </Box>
                    <Divider sx={{ m: 3 }} />
                    <Stack
                         spacing={{ xs: 1, sm: 5 }}
                         direction="row"
                         useFlexGap
                         alignItems="center"
                         justifyContent="center"
                         flexWrap="wrap"
                    >
                         {courses.map((course) => makeCard(course))}
                    </Stack>

                    <Divider sx={{ m: 3 }} />
               </Container>
               <Footer />
          </>
     );
}
function makeCard(course) {
     return (
          <Box
               sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "center",
                    boxShadow: 3,
                    flexGrow: 1,
                    borderRadius: 3,
                    p: 2,
                    maxWidth: "512px",
                    minHeight: "256px",
               }}
          >
               <Typography variant="h5" textAlign="center" gutterBottom>
                    <strong>{course.courseName}</strong>
               </Typography>
               <Typography
                    variant="body"
                    sx={{ flexGrow: 1 }}
                    textAlign="center"
                    gutterBottom
               >
                    {course.courseDetails}
               </Typography>

               <PopupButton course={course.courseName} />
          </Box>
     );
}
