import React, { useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grow,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  ArrowDownward,
  ArrowUpward,
  Cancel,
  Clear,
  Delete,
  RemoveCircle,
} from "@mui/icons-material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    borderRadius: "16px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

export default function FormDialog() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [open, setOpen] = React.useState(false);
  const [selectedCountries, setSelectedCountries] = React.useState([]);
  const [availableCountries, setAvailableCountries] = React.useState([
    "United_States",
    "United_kingdom",
    "New_Zealand",
    "Canada",
    "Italy",
    "Malta",
    "~ Select Country ~",
  ]);
  const greenColors = [
    "#001905",
    "#285430",
    "#5F8D4E",
    "#88bd82",
    "#93c3a7",
    "#bce0a6",
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    if (event.target.value !== "~ Select Country ~") {
      // setAge(event.target.value);
      setSelectedCountries([...selectedCountries, event.target.value]);
      setAvailableCountries(
        availableCountries.filter((country) => country !== event.target.value)
      );
    }
  };
  const handleMoveCountryUp = (countryToMove) => {
    const currentIndex = selectedCountries.indexOf(countryToMove);
    if (currentIndex > 0) {
      const newSelectedCountries = [...selectedCountries];
      const temp = newSelectedCountries[currentIndex];
      newSelectedCountries[currentIndex] =
        newSelectedCountries[currentIndex - 1];
      newSelectedCountries[currentIndex - 1] = temp;
      setSelectedCountries(newSelectedCountries);
    }
  };

  const handleMoveCountryDown = (countryToMove) => {
    const currentIndex = selectedCountries.indexOf(countryToMove);
    if (currentIndex < selectedCountries.length - 1) {
      const newSelectedCountries = [...selectedCountries];
      const temp = newSelectedCountries[currentIndex];
      newSelectedCountries[currentIndex] =
        newSelectedCountries[currentIndex + 1];
      newSelectedCountries[currentIndex + 1] = temp;
      setSelectedCountries(newSelectedCountries);
    }
  };
  const handleRemoveCountry = (countryToRemove) => {
    setSelectedCountries((prevSelectedCountries) =>
      prevSelectedCountries.filter((country) => country !== countryToRemove)
    );
    setAvailableCountries((prevAvailableCountries) =>
      [...prevAvailableCountries, countryToRemove].sort()
    );
  };
  const [visa, setVisaValue] = React.useState("dependent_visa");

  const handleVisaChange = (event) => {
    setVisaValue(event.target.value);
  };
  const [name, setName] = React.useState("");
  const [isNameValid, setNameValid] = useState(true);
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = React.useState("");
  const [isEmailValid, setEmailValid] = useState(true);
  const [emailError, setEmailError] = useState("");
  const [phone, setPhone] = React.useState("");
  const [isPhoneValid, setPhoneValid] = useState(true);
  const [phoneError, setPhoneError] = useState("");
  function resetForm() {
    setName("");
    setNameValid(true);
    setNameError("");

    setEmail("");
    setEmailValid(true);
    setEmailError("");

    setPhone("");
    setPhoneValid(true);
    setPhoneError("");

    setFormState("");
    setMsgColorState("green");
    setMsgLoadingState(false);
    setSelectedCountries([]);
    setAvailableCountries([
      "United_States",
      "United_kingdom",
      "New_Zealand",
      "Canada",
      "Italy",
      "Malta",
      "~ Select Country ~",
    ]);
    setVisaValue("dependent_visa");
  }
  const handleSubmit = () => {
    let isFormValid = true;

    validateName(name.trim());
    validateEmail(email);
    validatePhone(phone);

    if (!isNameValid) {
      isFormValid = false;
      setFormState("Please input your name");
      setMsgColorState("red");
    }

    if (!isEmailValid) {
      isFormValid = false;
      setFormState("Please input your email");
      setMsgColorState("red");
    }

    if (!isPhoneValid) {
      isFormValid = false;
      setFormState("Please input your phone number");
      setMsgColorState("red");
    }

    if (selectedCountries.length === 0) {
      isFormValid = false;
      setFormState("Please select countries");
      setMsgColorState("red");
    }

    if (isFormValid) {
      const apiUrl = "https://foreignroutes.in/api/api.php";

      const formData = new FormData();
      formData.append("name", name.trim());
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("visa", visa);
      const jsonCountries = JSON.stringify(
        selectedCountries.map((selectedCountry, index) => ({
          index: index,
          country: selectedCountry,
        }))
      );
      formData.append("countries", jsonCountries);
      setMsgLoadingState(true);
      fetch(apiUrl, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          setMsgLoadingState(false);
          //console.log(response.text());
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setMsgLoadingState(false);
          console.log("API response data:", data);
          if (data.status == "error") {
            // ERROR RESPONSE
            setFormState(data.message);
            setMsgColorState("red");
          } else {
            // SUCSSESFULL RESPONSE
            setFormState(data.message);
            setMsgColorState("green");
          }
        })
        .catch((error) => {
          setMsgLoadingState(false);
          console.error("Error:", error);
        });
    }
  };
  const [formState, setFormState] = React.useState("");
  const [msgColor, setMsgColorState] = React.useState("green");
  const [loading, setMsgLoadingState] = React.useState(false);
  return (
    <div>
      <Button
        color="secondary"
        onClick={handleClickOpen}
        sx={{ ml: 3, my: 2, borderRadius: 3 }}
        variant="outlined"
      >
        Apply&nbsp;Now
      </Button>
      <BootstrapDialog
        fullWidth={true}
        fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={Transition}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Apply for Registration
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <List sx={{ display: formState === "" ? "block" : "none" }}>
            <ListItem>
              <Box sx={{ width: 1 }}>
                <FormLabel sx={{ ml: 1 }}>Name</FormLabel>
                <TextField
                  id="outlined-basic"
                  label="Name"
                  error={!isNameValid}
                  helperText={nameError}
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                    const nameValue = event.target.value.trim();
                    validateName(nameValue);
                  }}
                  variant="outlined"
                  sx={{ width: 1, mt: 1 }}
                />
              </Box>
            </ListItem>

            <ListItem>
              <Box sx={{ width: 1 }}>
                <FormLabel sx={{ ml: 1 }}>Email</FormLabel>
                <TextField
                  id="outlined-basic"
                  label="Email"
                  error={!isEmailValid}
                  helperText={emailError}
                  value={email}
                  onChange={(event) => {
                    const emailValue = event.target.value;
                    setEmail(emailValue);
                    validateEmail(emailValue);
                  }}
                  variant="outlined"
                  sx={{ width: 1, mt: 1 }}
                />
              </Box>
            </ListItem>
            <ListItem>
              <Box sx={{ width: 1 }}>
                <FormLabel sx={{ ml: 1 }}>Phone</FormLabel>
                <TextField
                  id="outlined-basic"
                  label="Phone"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+91</InputAdornment>
                    ),
                  }}
                  value={phone}
                  error={!isPhoneValid}
                  helperText={phoneError}
                  onChange={(event) => {
                    setPhone(event.target.value);
                    const phoneValue = event.target.value;
                    validatePhone(phoneValue);
                  }}
                  variant="outlined"
                  sx={{ width: 1, mt: 1 }}
                />
              </Box>
            </ListItem>
            <ListItem>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  mx: 1,
                }}
              >
                <FormLabel id="demo-radio-buttons-group-label">Visa</FormLabel>

                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="dependent_visa"
                  name="radio-buttons-group"
                  value={visa}
                  onChange={handleVisaChange}
                  sx={{ display: "flex", flexDirection: "row" }}
                >
                  <FormControlLabel
                    value="dependent_visa"
                    control={<Radio />}
                    label="Dependent Visa"
                  />
                  <FormControlLabel
                    value="student_visa"
                    control={<Radio />}
                    label="Student Visa"
                  />
                  <FormControlLabel
                    value="work_permit"
                    control={<Radio />}
                    label="Work Permit"
                  />
                  <FormControlLabel
                    value="visitors_visa"
                    control={<Radio />}
                    label="Visitors Visa"
                  />
                </RadioGroup>
              </Box>
            </ListItem>
            <ListItem sx={{ display: "flex", flexFlow: "column" }}>
              <FormLabel
                sx={{
                  display: selectedCountries.length > 0 ? "block" : "none",
                  flexFlow: "column",
                  width: "100%",
                }}
              >
                Your Choices
              </FormLabel>
              {selectedCountries.map((selectedCountry, index) => (
                <Box sx={{ width: "100%", mb: 1, display: "flex" }} key={index}>
                  <IconButton
                    aria-label="Remove"
                    onClick={() => handleRemoveCountry(selectedCountry)}
                  >
                    <Cancel />
                  </IconButton>
                  <div
                    style={{
                      flexGrow: 1,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <strong style={{ color: greenColors[index] }}>
                      {`${index + 1} : ${selectedCountry.replace("_", " ")}`}
                    </strong>
                  </div>
                  <Box>
                    <IconButton
                      aria-label="move-up"
                      onClick={() => handleMoveCountryUp(selectedCountry)}
                      disabled={index === 0}
                    >
                      <ArrowUpward />
                    </IconButton>
                    <IconButton
                      aria-label="move-down"
                      onClick={() => handleMoveCountryDown(selectedCountry)}
                      disabled={index === selectedCountries.length - 1}
                    >
                      <ArrowDownward />
                    </IconButton>
                  </Box>
                </Box>
              ))}
              <Box
                sx={{
                  width: 1,
                  display: availableCountries.length <= 1 ? "none" : "block",
                }}
              >
                <FormControl sx={{ mt: 1, width: 1 }}>
                  <InputLabel id="c1">Choices</InputLabel>
                  <Select
                    labelId="c1"
                    id="demo-simple-select-helper"
                    value="~ Select Country ~"
                    label="Choices"
                    onChange={handleChange}
                  >
                    {availableCountries.map((country) =>
                      countriesList(country)
                    )}
                  </Select>
                </FormControl>
              </Box>
              <FormLabel
                sx={{
                  display: selectedCountries.length == 0 ? "block" : "none",
                  flexFlow: "column",
                  width: "100%",
                  color: "red",
                }}
              >
                Please Select Your Choices
              </FormLabel>
            </ListItem>
            <Divider sx={{ m: 2 }} />
            <ListItem sx={{ justifyContent: "center" }}>
              <Button
                color="secondary"
                sx={{
                  width: 1,
                  p: 1,
                  borderRadius: 3,
                  display: loading ? "none" : "block",
                }}
                size="large"
                variant="contained"
                onClick={handleSubmit}
              >
                Apply
              </Button>
              <CircularProgress
                sx={{ display: loading ? "block" : "none" }}
                color="secondary"
              />
            </ListItem>
          </List>
          <Box sx={{ display: formState === "" ? "none" : "block" }}>
            <Typography color={msgColor} variant="body" gutterBottom>
              {formState}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            sx={{ display: formState === "" ? "block" : "none" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setFormState("");
              resetForm();
            }}
            sx={{ display: formState === "" ? "none" : "block" }}
          >
            Ok
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );

  function validatePhone(phoneValue) {
    const phonePattern = /^[0-9]{10}$/; // Change the regular expression pattern to match your desired format
    if (!phonePattern.test(phoneValue)) {
      setPhoneValid(false);
      setPhoneError("Invalid phone number format");
    } else {
      setPhoneValid(true);
      setPhoneError("");
    }
  }

  function validateEmail(emailValue) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(emailValue)) {
      setEmailValid(false);
      setEmailError("Invalid email format");
    } else {
      setEmailValid(true);
      setEmailError("");
    }
  }

  function validateName(nameValue) {
    if (nameValue.length < 1) {
      setNameValid(false);
      setNameError("Invalid Name");
    } else {
      setNameValid(true);
      setNameError(true);
    }
  }
}
function countriesList(country) {
  return <MenuItem value={country}>{country.replace("_", " ")}</MenuItem>;
}
