import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { HistoryEdu } from "@mui/icons-material";
import { Grow, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    borderRadius: "16px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

function PopupButton(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const [apiMessage, setApiMessage] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // Clear form data and errors when the dialog is closed.
    setFormData({
      name: "",
      email: "",
      phoneNumber: "",
    });
    setErrors({
      name: "",
      email: "",
      phoneNumber: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (course) => {
    // Perform validation here and update errors state.
    const newErrors = {};

    if (formData.name.trim() === "") {
      newErrors.name = "Name is required";
    } else {
      newErrors.name = "";
    }

    if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = "Invalid email address";
    } else {
      newErrors.email = "";
    }

    if (!/^\d{10}$/.test(formData.phoneNumber)) {
      newErrors.phoneNumber = "Invalid phone number (10 digits required)";
    } else {
      newErrors.phoneNumber = "";
    }

    // Check if there are any errors. If no errors, you can proceed with form submission.
    if (!Object.values(newErrors).some((error) => error !== "")) {
      // Prepare the data to be sent to the API
      const data = new FormData();
      data.append("name", formData.name);
      data.append("email", formData.email);
      data.append("phoneNumber", formData.phoneNumber);
      data.append("course", course);
      // Make a Fetch request to send the data to the API
      fetch("https://foreignroutes.in/api/coaching//receive_data.php", {
        method: "POST",
        body: data,
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            // API request failed, handle error here
            return response.text().then((text) => {
              throw new Error(text);
            });
          }
        })
        .then((data) => {
          if (data.status === "success") {
            // API request was successful, you can handle success here
            setApiMessage("Data submitted successfully");
            console.log(data);
            //handleClose();
          } else {
            // API request succeeded but returned an error message
            setApiMessage("Error: " + data.message);
          }
        })
        .catch((error) => {
          // Handle network error
          console.error("Network error:", error);
          // You can update the UI to show a network error message.
        });
    } else {
      // Update the errors state with the new error messages.
      setErrors(newErrors);
    }
  };

  return (
    <div>
      <Button
        color="success"
        sx={{ borderRadius: 3 }}
        variant="contained"
        onClick={handleClickOpen}
        startIcon={<HistoryEdu />}
      >
        Apply Now
      </Button>
      <BootstrapDialog
        fullWidth={true}
        fullScreen={fullScreen}
        aria-labelledby="customized-dialog-title"
        TransitionComponent={Transition}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle sx={{ backgroundColor: "#1976d2", color: "white" }}>
          Apply for {props.course}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={!!errors.name}
            helperText={errors.name}
          />
          <TextField
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            label="Phone Number"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={() => handleSubmit(props.course)} color="secondary">
            Submit
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export default PopupButton;
