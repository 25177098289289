import { Card, CardContent } from "@mui/material";
import shadows from "@mui/material/styles/shadows";
import React from "react";

const container = {
  margin: "10px",
  display: "flex",
  justifyContent: "space-around",
  flexWrap: "wrap",
};
const box = {
  width: "300px",
  textAlign: "center",
  margin: "10px",
};

const countryName = [
  "CANADA",
  "Australia",
  "United Kingdom",
  "USA",
  "Germany",
  "South Africa",
  "Singapore",
  "New Zealand",
];
const countryInfo = [
  "Canada is world's second-largest country by total area spreading through Atlantic to the Pacific and northward into the Arctic Ocean, covering 9.98 million square kilometres (3.85 million square miles)",

  "Australia, officially the Commonwealth of Australia,[12] is a sovereign country comprising the mainland of the Australian continent, the island of Tasmania, and numerous smaller islands.",

  "The United Kingdom is a country with rich history and culture. It is this country that colonized most of the English-speaking countries of the world such as the United State, Singapore, India, and Nigeria.",

  "The United States of America (USA), commonly known as the United States (U.S. or US) or simply America, is a country comprising 50 states, a federal district, five major self-governing territories, and various possessions",

  "Germany is ranked among the world’s top destinations for international students. Thousands of scholars are coming from all around the world in this one of the best education paradises, every year.",

  "Since independence in 1968, Mauritius has developed from an agriculturally based economy to a middle-income spreader economy with rising industrial, financial, and tourist sectors.",

  "Singapore has grown to be a strategic link and important gateway for global investors. Located at the heart of Southeast Asia,",

  "New Zealand is inexplicably charming country with tremendously beautiful forests, beaches, landscapes, mountains, lakes, and exceptional biodiversity.",
];

export default function Countries() {
  return (
    <div className="container" style={container}>
      {countryInfo.map((countryInfo, index) => (
        <Card
          sx={{
            boxShadow: 1,
            borderRadius: 3,
            mt: 2,
            backgroundColor: index % 2 === 0 ? "lightgreen" : "lightblue",
          }}
        >
          <CardContent>
            <div className="box" style={box}>
              <h3 style={{ marginBottom: "20px" }}>{countryName[index]}</h3>
              <div className="text">
                <p key={index}>{countryInfo}</p>
              </div>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
}
