import { Box, Card, Container, Typography } from "@mui/material";
import Footer from "../footer/Footer";
export default function StudyAbroad(props) {
     return (
          <>
               <Container sx={{ mt: 10 }}>
                    <Card sx={{ borderRadius: 3, pl: 3 }}>
                         <div className="contact_head_bar">
                              <Box sx={{ width: "100%", flexGrow: 1 }}>
                                   <strong>#RightAdvice</strong>
                                   <h1>Study in USA</h1>
                                   <i>www.foreignroutes.in</i>
                              </Box>
                              <Box>
                                   <img
                                        style={{
                                             flexGrow: 1,
                                             width: "100%",
                                             objectFit: "cover",
                                        }}
                                        src="https://picsum.photos/1024/1024"
                                   ></img>
                              </Box>
                         </div>
                    </Card>
                    <Typography variant="title" gutterBottom>
                         QUICK FACTS
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                         About USA
                    </Typography>
               </Container>
               <Footer />
          </>
     );
}
